<template>
  <div class="page-tiendas">
    <div class="container-tiendas pb-5">
      <h1 class="w-100 text-center mb-5">Tiendas Clover</h1>
      <!-- <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4 px-2" :key="index" v-for="(tienda, index) in dataTiendasTemp">
                <div class="card-tienda">
                    <p class="fw-bold mb-2">{{tienda.ciudad.toLowerCase().replace(/^\w/, (d) => d.toUpperCase())}}</p>
                    <p class="mb-1">{{tienda.nombre.toLowerCase().replace(/^\w/, (d) => d.toUpperCase())}}</p>
                    <p class="mb-1">{{tienda.direccion}}</p>
                    <p class="mb-1">{{tienda.telefono}}</p>
                </div>
            </div> -->
    </div>
    <div class="col-12 pb-5" style="min-height: 500px">
      <iframe
        src="https://storage.googleapis.com/maps-solutions-fzjs95mwuw/locator-plus/17vq/locator-plus.html"
        width="100%"
        height="100%"
        style="border: 0; min-height: 500px"
        loading="lazy"
      >
      </iframe>
    </div>
    <div v-if="false" class="container-page-tiendas content-max-width">
      <div class="col-12 col-md-4 container-cities-page-tienda">
        <h4 class="mb-4">Encuentra tu tienda más cercana</h4>
        <div class="input-simple-clover mb-4">
          <label
            class="label-input"
            :style="select ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Departamento</label
          >
          <select
            v-if="citys.length > 0"
            id="city-select"
            v-model="select"
            class="w-100"
            @onChange="selectChange(select)"
          >
            <option
              v-for="(city, index) in citys"
              :key="index"
              class="my-2"
              :value="city.bodega_ciudad"
            >
              {{
                city.bodega_ciudad
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
            </option>
          </select>
        </div>
        <div style="max-height: 330px; overflow-y: scroll">
          <div
            v-for="(place, index) in placeSelects"
            :key="index"
            class="p-2"
            style="cursor: pointer"
            :class="
              placeSelected &&
              placeSelected.formatted_address == place.formatted_address
                ? 'place-select'
                : ''
            "
            @click="selectPlace(place)"
          >
            <b>{{ place.name }}</b
            ><br />
            <p class="mb-0">
              {{ place.formatted_address }}
            </p>
          </div>
        </div>
      </div>
      <div id="container-map" class="col-12 col-md-8">
        <div
          id="maps-content"
          style="
            min-height: 500px;
            height: 100%;
            width: 100%;
            position: relative;
            overflow: hidden;
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      select: 'BUCARAMANGA',
      citys: [],
      placeSelects: [],
      placeSelected: null,
      map: null,
      infowindow: null,
      dataTiendasTemp: null,
    }
  },
  methods: {
    loadResource: function(url, typeResource, callback) {
      return new Promise(resolve => {
        var resource
        if (typeResource == 'css') {
          resource = document.createElement('link')
          resource.type = 'text/css'
          resource.rel = 'stylesheet'
          resource.href = url
        } else {
          resource = document.createElement('script')
          resource.type = 'text/javascript'
          resource.src = url
        }
        resource.onload = () => {
          resolve()
          callback()
        }

        let headScript = document.querySelector('script')
        headScript.parentNode.insertBefore(resource, headScript)
      })
    },
    selectChange(city) {
      this.select = city.bodega_ciudad
      Array.from(document.querySelector('#city-select').options).forEach(
        function(item) {
          if (item.value == city.bodega_ciudad) {
            item.selected = true
          }
        },
      )
      document.querySelector('#city-select').dispatchEvent(new Event('change'))
    },
    async selectPlace(place) {
      if (window.innerWidth < 768) {
        $('html, body').animate(
          { scrollTop: $('#container-map').offset().top },
          500,
        )
      }
      /* eslint-disable */
      let map = this.map
      const marker = new google.maps.Marker({
        map,
        position: place.geometry.location,
        icon: {
          url: 'https://api.devclover.com.co/img/icons/maps/marker_map.svg',
        },
      })
      let url = ''
      let form = new FormData()
      form.append('place_id', place.place_id)
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/layout/get_data_mapa',
        data: form,
      })
        .then((response) => (url = response.data.result.url))
        .catch((error) => console.log('error', error))
      this.infowindow.setContent(
        '<div><strong>' +
          place.name +
          '</strong><br>' +
          place.formatted_address +
          "<br> <a target='_blank' href='" +
          url +
          "'>Ver en mapa</a></div>",
      )
      this.placeSelected = place
      this.infowindow.open(this.map, marker)
      let elThis = this
      setTimeout(() => {
        document
          .getElementsByClassName('gm-ui-hover-effect')[0]
          .addEventListener('click', () => {
            elThis.placeSelected = null
          })
        this.infowindow.addListener('visible', () => {
          marker.setMap(null)
        })
      }, 100)
    },
    async getCitys() {
      await this.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/layout/get_tiendas',
      })
        .then((response) => {
          this.citys = response.data.tiendas
          this.select = 'BUCARAMANGA'
        })
        .catch((e) => {
          console.log(e)
        })
    },
    async getDataTiendas() {
      await this.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/layout/get_tiendas_full',
      })
        .then((response) => {
          this.dataTiendasTemp = response.data.tiendas
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
  created() {
    sendFBQEvent(this, 'FindLocation', {})
  },
  async mounted() {
    document.title = 'Tiendas | Clover Tienda Online'
    await this.getDataTiendas()
    this.getCitys()
    /* eslint-disable */
    let elThis = this
    async function createMarker(place) {
      let map = elThis.map
      const marker = new google.maps.Marker({
        map,
        position: place.geometry.location,
        icon: {
          url: 'https://api.devclover.com.co/img/icons/maps/marker_map.svg',
        },
      })
      await google.maps.event.addListener(marker, 'click', async function () {
        let url = ''
        let form = new FormData()
        form.append('place_id', place.place_id)
        await elThis
          .axios({
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/layout/get_data_mapa',
            data: form,
          })
          .then((response) => (url = response.data.result.url))
          .catch((error) => console.log('error', error))
        elThis.infowindow.setContent(
          '<div><strong>' +
            place.name +
            '</strong><br>' +
            place.formatted_address +
            "<br> <a target='_blank' href='" +
            url +
            "'style='color: #0d6efd'>Ver en mapa</a></div>",
        )
        elThis.placeSelected = place
        elThis.infowindow.open(elThis.map, this)
        setTimeout(() => {
          document
            .getElementsByClassName('gm-ui-hover-effect')[0]
            .addEventListener('click', () => {
              elThis.placeSelected = null
              this.removeEventListener()
            })
        }, 100)
      })
    }
    function searchPlaces(city) {
      /* eslint-disable */
      let strQuery = 'cloverbolsos.com ' + city
      var request = {
        query: strQuery,
        fields: ['name', 'formatted_address', 'geometry'],
      }
      var service = new google.maps.places.PlacesService(elThis.map)
      service.textSearch(request, function (results, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          console.log(results)
          elThis.placeSelects = results
          for (var i = 0; i < results.length; i++) {
            createMarker(results[i])
          }
        }
      })
    }
    function initMap() {
      elThis.map = new google.maps.Map(
        document.getElementById('maps-content'),
        { center: { lat: 7.117079, lng: -73.11045 }, zoom: 14 },
      )
      elThis.infowindow = new google.maps.InfoWindow()

      searchPlaces('Bucaramanga')
      google.maps.event.addDomListener(
        document.getElementById('city-select'),
        'change',
        function () {
          let tempData = elThis.citys.find(
            (e) => e.bodega_ciudad == elThis.select,
          )
          $('option').css('font-weight', 'normal').css('padding-left', '0')
          $('option:selected')
            .css('font-weight', 'bold')
            .css('padding-left', '10px')
            .css('background-color', '#fff')
            .css('background', '#fff')
          searchPlaces(tempData.bodega_ciudad)
          elThis.map.panTo(
            new google.maps.LatLng(tempData.latitud, tempData.longitud),
          )
          map.setZoom(14)
        },
      )
    }
    window.initMap = initMap
    this.loadResource(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyA0BNKjwYaZVp0RUlb_Pa74xFHmTwy0PUk&callback=initMap&libraries=places',
      'js',
      function () {
        console.log('loadResource')
      },
    )
    // this.$gtag.pageview(this.$route.fullPath)
  },
}
</script>
